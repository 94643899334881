@import url('../../style/_vars.css');

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  display: grid;
  /* max-width: 100vw; */
  background: #fafafa;
  border-bottom: 1px solid #dadde6;
  z-index: 1;
}
.header-title {
  grid-area: 1 / 2 / span 1 / span 1;
  display: flex;
  align-items: center;
}
.app-header-title {
  font-size: var(--font-l);
  justify-self: flex-start;
  margin: 0;
  margin-right: 25px;
  white-space: nowrap;
}
.user-menu {
  grid-area: 1 / 3 / span 1 / span 1;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  margin-right: 25px;
}
.user-city {
  font-size: var(--font-r);
  white-space: nowrap;
}
.user-name {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-theme-primary);
  font-size: var(--font-s);
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.25s ease;
}
.user-name svg {
  margin-left: 5px;
  color: var(--color-theme-primary);
  transition: color 0.25s ease;
}
.user-name:hover {
  color: var(--color-theme-primary-d);
}
.user-name:hover svg {
  color: var(--color-theme-primary-d);
}

.header-container.project-setup-mode {
  background: #fafafa;
}

.header-container.workspace-mode .header-title {
  margin-left: 10px;
}

.header-container.project-setup-mode .header-title {
  margin-left: 22px;
}
