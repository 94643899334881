@import url('./style/_vars.css');
    .app-container {
      display: grid;
      grid-template-rows: 55px 1fr;
      grid-template-columns: 1fr;
      height: 100%;
    }
    .header-container {
      grid-area: 1 / 1 / span 1 / span 1;
    }
    .dashboard-container {
      grid-area: 1 / 1 / span 3 / span 1;
    }
    .results-container {
      grid-area: 2 / 1 / span 1 / span 1;
    }
    .login-container {
      grid-area: 2 / 1 / span 1 / span 1;
      align-self: center;
      margin: 0 auto;
      margin-bottom: 30vh;
    }
    .project-setup {
      grid-area: 2 / 1 / span 1 / span 1;
    }
    .first-proposal {
      grid-area: 2 / 1 / span 1 / span 1;
    }
    .workspace-loading-spinner {
      grid-area: 2 / 1 / span 1 / span 1;
    }

div {
  outline: 0.0px lightgray solid;  /* div outline  */
}