@import url('../../../style/_vars.css');

.conflict-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  max-width: 490px;
}

.conflict-modal-title,
.conflict-modal-subtitle,
.conflict-modal-message {
  text-align: center;
  color: var(--color-grey-d);
}

.conflict-modal-error-icon {
  color: var(--color-red);
}
.conflict-modal-error-icon svg {
  width: 2em;
  height: 2em;
}

.conflict-modal-title {
  font-weight: 400;
  font-size: var(--font-xl);
  white-space: nowrap;
}
.conflict-modal-footer {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.conflict-modal-refresh-button,
.conflict-modal-dismiss-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--font-l);
  padding: 15px 0 15px 0;
  cursor: pointer;
}

.conflict-modal-refresh-button {
  background-color: var(--color-theme-primary);
}
.conflict-modal-refresh-button:hover {
  background-color: var(--color-theme-primary-d);
}
.conflict-modal-dismiss-button {
  background-color: var(--color-grey);
}
.conflict-modal-dismiss-button:hover {
  background-color: var(--color-grey-d);
}
