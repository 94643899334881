@import url('./_vars.css');

/*content*/
html,
body {
  position: relative;
  height: 100%;
}
head,
script,
link {
  display: none;
}
p {
  display: inline-block;
}
span,
em,
strong {
  display: inline;
}
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}

header {
  background-color: var(--color-primary);
}

footer {
  width: 100%;
  background: var(--color-black);
  height: 40%;
}
main-nav {
  float: left;
  background-color: var(--color-primary);
}

a {
  color: var(--color-theme-primary);
  cursor: pointer;
  transition: color 0.25s ease;
}
a:hover {
  color: var(--color-theme-primary-d);
}

body {
  background: var(--color-white);
  font-size: 16px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
}

h1 {
  font-size: var(--font-xl);
  font-weight: 300;
  font-style: normal;
  color: var(--color-black);
}

h2 {
  font-size: var(--font-l);
  font-weight: 300;
  font-style: normal;
  color: var(--color-black);
}

h4,
h5,
li,
p {
  color: var(--color-darkgrey);
}

.dropdown {
  margin-bottom: 1%;
  width: 30%;
  color: var(--color-darkgrey);
  border: solid var(--color-lightgrey);
}

textarea,
input {
  resize: none;
  overflow-y: auto;
}
textarea::placeholder,
input::placeholder {
  color: var(--color-grey);
  font-size: 0.8em;
}

select {
  padding: var(--pad);
  cursor: pointer;
}

option {
  cursor: pointer;
}

.number-box {
  width: 30%;
}
.number-box input[type='number']::-webkit-inner-spin-button {
  transition: all 0.3s ease;
  opacity: 1;
}

.radio-button {
  text-decoration: none;
  float: left;
}

input[type='submit'] {
  background: var(--color-primary);
  color: var(--color-white);
  padding: var(--pad);
  padding-left: calc(var(--pad) * 4);
  padding-right: calc(var(--pad) * 4);
  border: none;
  cursor: pointer;
}
input[type='submit']:hover {
  color: var(--color-white);
  background: var(--color-secondary);
}

input[type='file']::-webkit-file-upload-button {
  border: none;
  background: var(--color-secondary);
  color: var(--color-white);
}
input[type='file']::-webkit-file-upload-button:hover {
  background: var(--color-primary);
  color: var(--color-lightgrey);
}

input[type='password'] {
  background: #d7d7d7;
  border: 1px solid #bebebe;
}

.logo {
  width: 15em;
  padding: 5%;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.hide {
  display: none;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.inline-field input,
.inline-field label,
.inline-field div,
.inline-field button,
.inline-field a {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.font-s {
  font-size: var(--font-s);
}

.click {
  cursor: pointer;
}

#root {
  height: 100%;
}

.sm-circle {
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: var(--pad);
}
.red {
  color: var(--color-red);
  border-color: var(--color-red);
}
.darkred {
  color: var(--color-red);
  border-color: var(--color-darkred);
}
.blue {
  color: var(--color-blue);
  border-color: var(--color-blue);
}
.darkblue {
  color: var(--color-darkblue);
  border-color: var(--color-darkblue);
}
.green {
  color: var(--color-green);
  border-color: var(--color-green);
}
.yellow {
  color: var(--color-yellow);
  border-color: var(--color-yellow);
}

.download-button {
  color: var(--color-white);
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  cursor: pointer;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.align-top {
  display: flex;
  align-items: flex-start;
}

.grid-align-columns {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-input {
  min-width: 60px !important;
  max-width: 120px !important;
}

.wide-number-input {
  min-width: 75px !important;
  max-width: 200px !important;
}

.number-input input {
  text-align: center;
}

.number-input-trend input {
  text-align: center;
}

.number-input-right input {
  text-align: right;
  padding-right: 5px;
}

.align-with-number-input input {
  height: 19px;
}

.number-input-row {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
}

.fit-content {
  width: fit-content;
}

.inline-row {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: center;
}

.Toastify__toast-container--top-right {
  top: 100px !important;
}

.Toastify__toast--success {
  background: var(--color-theme-primary) !important;
}

.Toastify__toast--error {
  background: var(--color-theme-error) !important;
}

.Toastify__toast--warning {
  background: var(--color-theme-warning) !important;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
